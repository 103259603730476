import axiosInstance from "../Axios";

export class LoginApi {
  static Login = async (data: any) => {
    try {
      const res = await axiosInstance({
        method: "post",
        url: "/web/events/login",
        data: data,
      });
      return {
        data: res.data,
        response_code: res?.data?.response_code,
      };
    } catch (err: any) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };

  static LoginToWeb = async (data: any) => {
    try {
      const res = await axiosInstance({
        method: "post",
        url: "/web/auth/login",
        data: {
          email: data?.email,
          password: data?.password,
          event_id: data?.event_id,
        },
      });
      return {
        data: res.data,
        response_code: res?.data?.response_code,
      };
    } catch (err: any) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };

  static VerifyInvite = async ({
    data,
    event_id,
  }: {
    data: any;
    event_id: string;
  }) => {
    try {
      const res = await axiosInstance({
        method: "post",
        url: `/web/events/${event_id}/verify-invite`,
        data: data,
      });
      return {
        data: res.data,
        response_code: res?.data?.response_code,
      };
    } catch (err: any) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };

  static changePassword = async (data: any) => {
    try {
      const res = await axiosInstance({
        method: "post",
        url: `/web/auth/change-password`,
        data: data,
      });
      return {
        data: res.data,
        response_code: res?.data?.response_code,
      };
    } catch (err: any) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };
}
