import { LocalStorageHelpers } from "../../components/helpers/localstorage.helpers";
import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

axiosInstance.interceptors.request.use((config: any) => {
  const accessToken =
    LocalStorageHelpers.getAccessToken() ||
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWMzNzkxMzA4M2Y4YjZhYWRjODA3NWUiLCJ1c2VyX2lkIjoiNjVjMzU3MzI3MjRiZDA3ZDcyNmVlNzc4IiwiZXZlbnRfaWQiOiI2NWMzNThhMDUyMWRjNWE0YzRiYjNlNTkiLCJ1c2VyX3R5cGUiOiJkZWxlZ2F0ZSIsImludml0YXRpb25fdXNlcm5hbWUiOiJuaXRlbnNvbGFua2kwMkBnbWFpbC5jb20iLCJjcmVhdGVkQXQiOiIyMDI0LTAyLTA3VDEyOjM1OjMxLjA1MVoiLCJ1cGRhdGVkQXQiOiIyMDI0LTAyLTA3VDEyOjM1OjMxLjA1MVoiLCJfX3YiOjAsImlhdCI6MTcwNzU0NjQ1OCwiZXhwIjoxNzA4MTUxMjU4fQ.hgHd1MptbimGFOkhA-vQDp7iVCED0lTUrbHQQI1T_DU";

  if (accessToken) {
    config.headers["Authorization"] = `Bearer ${accessToken || ""}`;
  }
  return config;
});

// axiosInstance.interceptors.request.use((config) => {
//   const token = localStorage.getItem("access_token");
//   config.params = config.params || {};
//   config.params["auth"] = token;
//   return config;
// });

export default axiosInstance;
