import "./App.css";

import { lazy, Suspense } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import Exhibitors from "./components/user/exhibitor/Exhibitors";
import Hotels from "./components/hotels/Hotels";

const Login = lazy(() => import("./components/login/Login"));
// const Home = lazy(() => import("./components/home/Home"));
const HomePageEvent = lazy(() => import("./components/home/HomePageEvent"));
const UserProfile = lazy(() => import("./components/userProfile"));
const EventInfo = lazy(() => import("./components/eventinfo"));
const ConferenceAgenda = lazy(() => import("./components/conferenceagenda"));
const ExhibitionStandInformation = lazy(
  () => import("./components/exhibitorInformation/ExhibitionStandInformation")
);
const AdditionalOrders = lazy(
  () => import("./components/exhibitorInformation/AdditionalOrders")
);
const ShippingInformation = lazy(
  () => import("./components/exhibitorInformation/AdditionalOrders")
);
const ExhibitorInsurance = lazy(
  () => import("./components/exhibitorInformation/ExhibitorInsurance")
);
const ProductDemos = lazy(
  () => import("./components/exhibitorInformation/ProductDemos")
);
const Parking = lazy(() => import("./components/exhibitorInformation/Parking"));
const Deadlines = lazy(
  () => import("./components/exhibitorInformation/Deadlines")
);
const RaisingYourProfile = lazy(
  () => import("./components/exhibitorInformation/RaisingYourProfile")
);
const MarketingGraphics = lazy(
  () => import("./components/exhibitorInformation/MarketingGraphics")
);
const GeneralInformation = lazy(
  () => import("./components/exhibitorInformation/GeneralInformation")
);
const MeetingsManagement = lazy(
  () => import("./components/meetings/meetingsManagement/MeetingsManagement")
);
const MeetingDashboard = lazy(
  () => import("./components/meetings/meetingDashboard/MeetingControlPanel")
);
const MeetingRequestManager = lazy(
  () => import("./components/meetings/requestAcceptMeetings/layout")
);
const ViewSchedule = lazy(
  () => import("./components/meetings/viewSchedule/ViewSchedule")
);
const NetworkingEvents = lazy(
  () => import("./components/meetings/networkingEvents/NetworkingEvents")
);
const DelegateList = lazy(() => import("./components/user/delegate"));
const AcceptMeeting = lazy(
  () => import("./components/meeting/accept/AcceptMeeting")
);
const RescheduleMeeting = lazy(
  () => import("./components/meeting/reschedule/RescheduleMeeting")
);
const DeclineMeeting = lazy(
  () => import("./components/meeting/decline/DeclineMeeting")
);
const ProtectedRoutes = lazy(
  () => import("./components/protectedRoutes/ProtectedRoutes")
);
const Delegate = lazy(() => import("./components/user/delegate"));
const Sponsors = lazy(() => import("./components/sponsors"));
const Faqs = lazy(() => import("./components/exhibitorInformation/faqs/Faqs"));
const VenueMap = lazy(() => import("./components/venueMap/VenueMap"));
const NewHome = lazy(() => import("./components/home/NewHome"));

const DashboardInfo = lazy(() => import("./components/dashboard"));
const Helpdesk = lazy(() => import("./components/helpdesk"));

function App() {
  return createBrowserRouter(
    createRoutesFromElements(
      <Route path="">
        <Route
          path="event/:event_id/dashboard"
          element={
            <Suspense fallback={<div></div>}>
              <ProtectedRoutes Component={DashboardInfo} />
            </Suspense>
          }
        />
        <Route
          path="event/:event_id/helpdesk"
          element={
            <Suspense fallback={<div></div>}>
              <ProtectedRoutes Component={Helpdesk} />
            </Suspense>
          }
        />
        <Route
          path="/event/:event_id/login"
          // element={<ProtectedRoutes Component={Login} />}
          element={
            <Suspense fallback={<div></div>}>
              <Login />
            </Suspense>
          }
        />
        <Route
          path="event/:event_id/user-profile"
          element={
            <Suspense fallback={<div></div>}>
              <ProtectedRoutes Component={UserProfile} />
            </Suspense>
          }
        />
        <Route
          path="event/:event_id/event-info"
          element={
            <Suspense fallback={<div></div>}>
              <ProtectedRoutes Component={EventInfo} />{" "}
            </Suspense>
          }
        />
        <Route
          path="/accept-meeting/:meeting_id"
          element={
            <Suspense fallback={<div></div>}>
              <AcceptMeeting />
            </Suspense>
          }
        />
        <Route
          path="/decline-meeting/:meeting_id"
          element={
            <Suspense fallback={<div></div>}>
              <DeclineMeeting />{" "}
            </Suspense>
          }
        />
        <Route
          path="/reschedule-meeting/:meeting_id"
          element={
            <Suspense fallback={<div></div>}>
              <RescheduleMeeting />{" "}
            </Suspense>
          }
        />
        <Route
          path="event/:event_id/conference-agenda"
          element={
            <Suspense fallback={<div></div>}>
              <ProtectedRoutes Component={ConferenceAgenda} />{" "}
            </Suspense>
          }
        />
        <Route
          path="/event/:event_id/exhibitor-information/general-information"
          element={
            <Suspense fallback={<div></div>}>
              <ProtectedRoutes Component={GeneralInformation} />{" "}
            </Suspense>
          }
        />

        <Route
          path="/event/:event_id/exhibitor-information/faqs"
          element={
            <Suspense fallback={<div></div>}>
              <ProtectedRoutes Component={Faqs} />{" "}
            </Suspense>
          }
        />
        <Route
          path="/event/:event_id/exhibitor-information/exhibition-stand-information"
          element={
            <Suspense fallback={<div></div>}>
              <ProtectedRoutes Component={ExhibitionStandInformation} />{" "}
            </Suspense>
          }
        />
        <Route
          path="/event/:event_id/exhibitor-information/additional-orders"
          element={
            <Suspense fallback={<div></div>}>
              <ProtectedRoutes Component={AdditionalOrders} />{" "}
            </Suspense>
          }
        />
        <Route
          path="/event/:event_id/exhibitor-information/shipping-information"
          element={
            <Suspense fallback={<div></div>}>
              <ProtectedRoutes Component={ShippingInformation} />{" "}
            </Suspense>
          }
        />
        <Route
          path="/event/:event_id/exhibitor-information/exhibitor-insurance"
          element={
            <Suspense fallback={<div></div>}>
              <ProtectedRoutes Component={ExhibitorInsurance} />{" "}
            </Suspense>
          }
        />
        <Route
          path="/event/:event_id/exhibitor-information/product-demos"
          element={
            <Suspense fallback={<div></div>}>
              <ProtectedRoutes Component={ProductDemos} />{" "}
            </Suspense>
          }
        />
        <Route
          path="/event/:event_id/exhibitor-information/parking"
          element={
            <Suspense fallback={<div></div>}>
              <ProtectedRoutes Component={Parking} />{" "}
            </Suspense>
          }
        />
        <Route
          path="/event/:event_id/exhibitor-information/deadlines"
          element={
            <Suspense fallback={<div></div>}>
              <ProtectedRoutes Component={Deadlines} />{" "}
            </Suspense>
          }
        />
        <Route
          path="/event/:event_id/exhibitor-information/raising-your-profile"
          element={
            <Suspense fallback={<div></div>}>
              <ProtectedRoutes Component={RaisingYourProfile} />{" "}
            </Suspense>
          }
        />
        <Route
          path="/event/:event_id/exhibitor-information/marketing-graphics"
          element={
            <Suspense fallback={<div></div>}>
              <ProtectedRoutes Component={MarketingGraphics} />{" "}
            </Suspense>
          }
        />
        <Route
          path="/event/:event_id/conference-agenda"
          element={
            <Suspense fallback={<div></div>}>
              <ProtectedRoutes Component={ConferenceAgenda} />{" "}
            </Suspense>
          }
        />
        <Route
          path="/event/:event_id/1-1-meetings"
          element={
            <Suspense fallback={<div></div>}>
              <ProtectedRoutes Component={MeetingDashboard} />{" "}
            </Suspense>
          }
        />

        <Route
          path="/event/:event_id/venue-map"
          element={
            <Suspense fallback={<div></div>}>
              <ProtectedRoutes Component={VenueMap} />
            </Suspense>
          }
        />
        <Route
          path="/event/:event_id/1-1-meetings/meetings-management"
          element={
            <Suspense fallback={<div></div>}>
              <ProtectedRoutes Component={MeetingsManagement} />{" "}
            </Suspense>
          }
        />
        <Route
          path="/event/:event_id/1-1-meetings/request-accept-meetings"
          element={
            <Suspense fallback={<div></div>}>
              <ProtectedRoutes Component={MeetingRequestManager} />{" "}
            </Suspense>
          }
        />
        <Route
          path="/event/:event_id/1-1-meetings/view-schedule"
          element={
            <Suspense fallback={<div></div>}>
              <ProtectedRoutes Component={ViewSchedule} />{" "}
            </Suspense>
          }
        />
        <Route
          path="/event/:event_id/1-1-meetings/networking-events"
          element={
            <Suspense fallback={<div></div>}>
              <ProtectedRoutes Component={NetworkingEvents} />{" "}
            </Suspense>
          }
        />

        <Route
          path="/event/:event_id/delegates"
          element={
            <Suspense fallback={<div></div>}>
              <ProtectedRoutes Component={Delegate} />
            </Suspense>
          }
        />

        <Route
          path="/event/:event_id/exhibitors"
          element={
            <Suspense fallback={<div></div>}>
              <ProtectedRoutes Component={Exhibitors} />
            </Suspense>
          }
        />
         <Route
          path="/event/:event_id/hotels"
          element={
            <Suspense fallback={<div></div>}>
              <ProtectedRoutes Component={Hotels} />
            </Suspense>
          }
        />

        <Route path="event/:event_id/general-information">
          <Route
            index
            element={
              <Suspense fallback={<div></div>}>
                <ProtectedRoutes Component={GeneralInformation} />{" "}
              </Suspense>
            }
          />
        </Route>

        <Route
          path="event/:event_id/sponsors"
          element={
            <Suspense fallback={<div></div>}>
              <ProtectedRoutes Component={Sponsors} />{" "}
            </Suspense>
          }
        />

        <Route
          path="/"
          element={
            <Suspense fallback={<div></div>}>
              <HomePageEvent />
            </Suspense>
          }
        />
      </Route>
    )
  );
}

export default App;
