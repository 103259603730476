import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  changePasswordModal: false,
};

export const passwordSlice = createSlice({
  name: "password",
  initialState,
  reducers: {
    setChangePasswordModal: (state) => {
      state.changePasswordModal = !state.changePasswordModal;
    },
  },
});

export const passwordAction = passwordSlice.actions;

export const passwordReducer = passwordSlice.reducer;
