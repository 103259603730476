import { Link, useParams } from "react-router-dom";
import "./common.scss";
import { getImageUrl, httpUrlFormate } from "./userDetails/userDetails";
import { Swiper, SwiperSlide } from "swiper/react";
import { GoArrowLeft, GoArrowRight } from "react-icons/go";
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { useEffect, useState } from "react";
import { SponsorsApi } from "../utils/api/user/SponsorsApi";
import ResponseCodes from "../utils/response-codes";
import { toast } from "react-hot-toast";

const SponsoredBySlider = () => {

  let params = useParams();
  const eventId = params?.event_id || "";

  const [companies, setCompanies] = useState([] as any);
  const [state, setState] = useState({
    isLoading: true,
    sponsors: [] as any,
  })

  const getSponsors = async () => {
    setState({
      ...state,
      isLoading: true,
    });

    let res = await SponsorsApi.getEventSponsors(eventId);

    switch (res?.response_code) {
      case ResponseCodes.GET_SUCCESS:
        setState({
          ...state,
          isLoading: false,
          sponsors: res?.data?.data?.docs,
        });
        break;
      case ResponseCodes.NOT_FOUND:
        toast.error(res?.data?.message);
        break;

      default:
        toast.error(res?.data?.error || "Internal server error");
    }
  };

  useEffect(() => {
    getSponsors();
  }, []);

  useEffect(() => {
    if(!state.isLoading) {
      var companies = state.sponsors?.map((sponsor: any) => sponsor.company);
      companies = companies.reduce((a: any, c: any) => {
        if (c && !a.find((v : any) => v?._id === c?._id)) {
          a.push(c);
        }
        return a;
      }, []);
      setCompanies(companies);
    }
  } , [state]);

  const sponsorsSlider = {
      spaceBetween:20,
      slidesPerView:1,
      loop:true,
      navigation:{
        nextEl: '.arrow-right-twt',
        prevEl: '.arrow-left-twt',
      },
      breakpoints:{
        576: {
          slidesPerView: 3,
        },
        991: {
          slidesPerView: 4,
        },
        1400: {
          slidesPerView: 5,
        },
      },
      modules:[Navigation]
  };

  const [addClassToSponsorDiv, setAddClassToSponsorDiv] = useState(true);

  useEffect(() => {
      var width = document.body.clientWidth;
      var length = companies?.length || 0;        

      switch(true) {
          case (width < 576 && length > 1) : 
              setAddClassToSponsorDiv(false);
              break;
          case (width < 991 && length > 3) : 
              setAddClassToSponsorDiv(false);
              break;
          case (width < 1400 && length > 4) : 
              setAddClassToSponsorDiv(false);
              break;
          case (width >= 1400 && length > 5) : 
              setAddClassToSponsorDiv(false);
              break;
      }
  }, [document.body.clientWidth, companies]);

  const getSponsorSlider = () => {
      if(state.sponsors?.length > 0) {
        if(companies?.length > 0) {
          return (
              <section className="event-sponsors-section">
                  <div className="event-sponsors-title-div">
                      <h3 className="event-sponsors-titel">Event Sponsors</h3>
                  </div>
                  <div className={"sponsors-slider-div" + (addClassToSponsorDiv ? " slider-sponsors-div" : "")}>
                      <Swiper {...sponsorsSlider}>
                      {companies.map((company: any, index: number) => (
                          <SwiperSlide key={index}>
                              <Link
                                  to={company?.company_URL ? httpUrlFormate(company?.company_URL) : ""}
                                  key={company?._id}
                                  target="_blank"
                                  className={company?.company_URL ? "" : "disable_link"}
                              >
                              {company?.company_logo ? (
                                <div className="sponsors-slider-item" key={company?._id}>
                                  <img src={getImageUrl(company.company_logo)} alt="sponsors logo" />
                                </div>
                              ) : (
                                <div className="sponsors-slider-item">
                                  <svg fill="none" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><g fill="rgb(0,0,0)"><path d="m6.5 8c-.27614 0-.5.22386-.5.5v1c0 .27614.22386.5.5.5h1c.27614 0 .5-.22386.5-.5v-1c0-.27614-.22386-.5-.5-.5z"/><path d="m6 11.5c0-.2761.22386-.5.5-.5h1c.27614 0 .5.2239.5.5v1c0 .2761-.22386.5-.5.5h-1c-.27614 0-.5-.2239-.5-.5z"/><path d="m6.5 14c-.27614 0-.5.2239-.5.5v1c0 .2761.22386.5.5.5h1c.27614 0 .5-.2239.5-.5v-1c0-.2761-.22386-.5-.5-.5z"/><path d="m9 8.5c0-.27614.22386-.5.5-.5h1c.2761 0 .5.22386.5.5v1c0 .27614-.2239.5-.5.5h-1c-.27614 0-.5-.22386-.5-.5z"/><path d="m9.5 11c-.27614 0-.5.2239-.5.5v1c0 .2761.22386.5.5.5h1c.2761 0 .5-.2239.5-.5v-1c0-.2761-.2239-.5-.5-.5z"/><path d="m9 14.5c0-.2761.22386-.5.5-.5h1c.2761 0 .5.2239.5.5v1c0 .2761-.2239.5-.5.5h-1c-.27614 0-.5-.2239-.5-.5z"/><path d="m12.5 8c-.2761 0-.5.22386-.5.5v1c0 .27614.2239.5.5.5h1c.2761 0 .5-.22386.5-.5v-1c0-.27614-.2239-.5-.5-.5z"/><path d="m12 11.5c0-.2761.2239-.5.5-.5h1c.2761 0 .5.2239.5.5v1c0 .2761-.2239.5-.5.5h-1c-.2761 0-.5-.2239-.5-.5z"/><path d="m12.5 14c-.2761 0-.5.2239-.5.5v1c0 .2761.2239.5.5.5h1c.2761 0 .5-.2239.5-.5v-1c0-.2761-.2239-.5-.5-.5z"/><path clip-rule="evenodd" d="m15.7751 2.15414c.6267-.14461 1.2249.3313 1.2249.97439v2.06246l3.4472 1.7236c.3388.16939.5528.51566.5528.89443v13.19098h.5c.2761 0 .5.2239.5.5s-.2239.5-.5.5h-.9986-.0014-.0014-3.9966-.002-.002-12.99603-.00197-.00197-.99803c-.27614 0-.5-.2239-.5-.5s.22386-.5.5-.5h.5v-15.10224c0-.46566.32141-.86968.77514-.97439zm4.2249 18.84586v-13.19098l-3-1.5v14.69098zm-4-15.51084c-.0001.00698-.0001.01395 0 .02091v15.48993h-4v-2c0-.5523-.4477-1-1-1h-2c-.55228 0-1 .4477-1 1v2h-4v-15.10224l12-2.76923zm-7 13.51084v2h2v-2z" fill-rule="evenodd"/></g></svg>
                                </div>
                              )}
                              
                              </Link>
                          </SwiperSlide>
                      ))}
                      </Swiper>
                      <div className="button-container">
                          <button className="prev arrow-left-twt">
                              <GoArrowLeft className="sub_icon" />
                          </button>
                          <button className="next arrow-right-twt">
                              <GoArrowRight className="sub_icon" />
                          </button>
                      </div>
                  </div>
              </section>
          )
        }
      }
    }
    
    return (
        <>
        {getSponsorSlider()}
        </>
    )
}

export default SponsoredBySlider;