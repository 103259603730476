import { AnyAaaaRecord } from "dns";
import axiosInstance from "./Axios";

export class ProfileApiServices {
  static getProfile = async () => {
    try {
      const res = await axiosInstance({
        method: "get",
        url: `web/users/profile`,
      });

      return {
        data: res.data,
        response_code: res?.data?.response_code,
      };
    } catch (err: any) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };

  static editDelegate = async (data: any) => {
    try {
      const res = await axiosInstance({
        method: "post",
        url: `web/users/profile/update`,
        data,
      });

      return {
        data: res.data,
        response_code: res?.data?.response_code,
      };
    } catch (err: any) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };

  static getCompanies = async () => {
    try {
      const res = await axiosInstance({
        method: "get",
        url: `/web/companies`,

        params: {
          page: 1,
          limit: 100,
          search: "",
          created_at: "",
        },
      });

      return {
        data: res.data,
        response_code: res?.data?.response_code,
      };
    } catch (err: any) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };

  static getCategory = async () => {
    try {
      const res = await axiosInstance({
        method: "get",
        url: `/web/industries`,

        params: {
          page: 1,
          limit: 100,
          search: "",
        },
      });

      return {
        data: res.data,
        response_code: res?.data?.response_code,
      };
    } catch (err: any) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };

  static updateProfileImage = async (data: any) => {
    try {
      const res = await axiosInstance({
        method: "post",
        url: `/web/users/profile/update-profile-image`,
        data,
      });

      return {
        data: res.data,
        response_code: res?.data?.response_code,
      };
    } catch (err: any) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };
}
