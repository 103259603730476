import axiosInstance from "../Axios";

export class SponsorsApi {
  static getEventSponsors = async (event_id: string) => {
    try {
      const res = await axiosInstance({
        method: "get",
        url: `/admins/events/${event_id}/sponsors`,
        params: {
          page: 1,
          limit: 1000,
          search: "",
          created_at: "",
        },
      });
      return {
        data: res.data,
        response_code: res?.data?.response_code,
      };
    } catch (err: any) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };
}
